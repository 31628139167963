
@font-face {
  font-family: 'Font';
  src: url('./font.ttf') format('truetype');
}

@font-face {
  font-family: 'Font2';
  src: url('./font2.ttf') format('truetype');
}


:root {
  --red: rgba(200, 27, 27, 1);
  --gold: rgba(202, 171, 105, 1);
  --black: #000;
  --white: rgba(255, 255, 255, 1);
  --linear: linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
}

html {
	scroll-behavior: smooth;
}

.container {
	box-sizing: border-box;
	background-color: var(--white);
	overflow-x: hidden;
}

.top-apps {
	background-image: url("./vik\ logo\ blurred.png");
	background-blend-mode: luminosity;
	background-color: var(--black);
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
}

.claim-app {
	background-image: url("./vik\ logo\ blurred.png");
	background-blend-mode: luminosity;
	background-color: var(--white);
	background-repeat: no-repeat;
	background-size: cover;
}

.app {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-family: 'Font', sans-serif;
}

.app-header {
  padding: 0 20px;  
  width: 97%;
  margin-top: 20px;
}

.top-logo {
	position: fixed;
	z-index: 2000;
}

.header-right {
 	justify-content: flex-end;
  	align-items: top;
  	display: flex;
	position: relative;
}

.logo {
  height: 50px;  /* Adjust this value for your needs */
  width: auto;
}

.header-button{
  background-color: var(--red);
  text-transform: uppercase;
  padding: 5px 10px;
  color: var(--white);
  font-size: 17px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  text-align: center;
  display: inline-block;
}

.arrow {
  border: solid var(--white);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 5px;
  margin-bottom: 4px!important;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.content-button {
	background-color: var(--red);
	color: var(--white);
	margin-top: 20px;
	font-family: 'Font2', sans-serif;
	padding: 10px 50px;
	border: none;
	cursor: pointer;
	text-align: center;
	display: inline-block;
	font-size: 16px;
	font-weight: 500;
	transition-duration: 0.4s;
	border-radius: 5px;
}


.content-button:hover {
  color: #ffffff;
  font-family: 'Font2', sans-serif;
}

.title{
  color: var(--red);
  text-align: left;
  font-size: 150px;
  margin-top: 0px;
  font-weight: 600;
  margin-left: 0px;
  
}

.hero-content h2 {
	color: var(--gold);
	font-family: Font;
	font-size: 66px;
	font-weight: 700;
	letter-spacing: normal;
	line-height: 60px;
	text-align: left;
	text-transform: uppercase;
	width: 100%;
}

.hero-content h3 {
	color: var(--red);
	font-family: Font2;
	font-size: 45px;
	font-weight: 700;
	line-height: 40px;
	letter-spacing: 0em;
	text-align: left;
	margin-top: -3rem;
	text-transform: uppercase;
	width: 100%;
}

.hero-content p {
	color: var(--white);
	font-family: Font;
	font-size: 15px;
	font-weight: 500;
	line-height: 27px;
	margin-bottom: 4rem;
	width: 100%;
}

.hero-content .claim-text {
	color: var(--black);
}

.hero-content .claim-button {
	font-family: Font2;
	font-size: 15px;
	font-weight: 500;
	line-height: 18px;
	padding: .8rem 1.5rem;
}


.hero-content a {
	color: var(--white);
	font-family: Font2;
	font-size: 15px;
	font-weight: 700;
	line-height: 18px;
	text-decoration: none;
}

.hero-content a i {
	margin-right: .5rem;
}

.hero-content-head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 6rem;
}

.hero-content-head .text-container {
	margin-top: -21rem;
}

.youtube{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 1000px;

}

.youtube-video {
    position: relative;
    width: 100%;
    max-width: 1000px; /* Adjust this value based on your desired maximum width */
    padding-bottom: 56.25%; /* This value is for 16:9 aspect ratio videos. Adjust if needed. */
    overflow: hidden;
}

.youtube-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70%;
}

.hero-text-container {
	padding: 0 6rem;
	margin-top: -23rem;
	display: flex;
	width: 900px;
	flex-direction: column-reverse;
	margin-bottom: 10rem;
}

.claim-app .hero-content {
	flex-direction: column;
	align-items: start;
	padding-top: 8rem;
}

.claim-form {
	align-self: center;
	padding: 2rem;
	background-color: var(--black);
	color: var(--white);
	width: 30%;
	margin: auto;
	margin-bottom: -15rem;
	margin-top: 6rem;
	z-index: 1200;
}

.claim-form input {
	border: none;
	border-bottom: 1px solid var(--white);
	background-color: inherit;
	display: block;
	padding: .8rem 0;
	color: var(--white);
}

.claim-form .address-input {
	width: 100%;
}

.claim-form  div {
	display: flex;
	justify-content: space-between;
}

.claim-form .claim-check {
	justify-content: center;
	margin-top: 2rem!important;
}

.claim-check input {
	margin-right: 1rem!important;
}

.claim-form .claim-select {
	justify-content: center;
	margin-top: 2rem!important;
	border: none;
	outline: none;
}

.claim-select select {
	padding: .5rem;
	background-color: transparent;
	color: var(--white);
	border: 1px solid var(--white);
	border-radius: 1px;
}

.claim-select option {
	color: var(--black)
}

.claim-form .claim-submit {
	justify-content: center;
	margin-top: .6rem;
}

.claim-form button {
	font-family: Font2;
	font-size: 15px!important;
	font-weight: 600;
	line-height: 18px;
	padding: .5rem 1rem;
	margin-top: 2rem;
}



.claim-form input:focus, select:focus {
	outline: none;
}


.main-content {
	width: 90%; /* or any other value that suits your layout */
	margin: 0 auto;
}

.main-content2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: top;
  width: 90%; 
  margin: 0 auto;
}

.section-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.section-container:nth-child(even) {
  flex-direction: row-reverse;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10rem;
}

.claim-form .image-container2 {
	margin: 0;
}

.claim-form .image-container2 img {
	 width: auto;
}

.image-container2 button {
	background-color: var(--red)!important;
}

.right-side-image {
  margin-bottom: 20px;
  width: 180px;
}

.header-content {
	background-image: linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0) 100%) , url("./winebg.png");
	background-repeat: no-repeat;
	background-size: 100%;
	width: 100%;
	height: 100vh;
	margin-top: 10px;
	display: flex;
	align-items: center;
	justify-content: end;
}

.header-content .text-container {
	width: 40%;
	margin-right: 4rem;
}

.text-container {
	width: 50%;
	color: var(--white);
}

.text-container p {
	font-family: Font2;
	font-size: 15px;
	font-weight: 700;
	line-height: 27px;

}

.section-1-content {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 10px;
	height: 100vh;
}

.section-1-content .text-container {
	display: flex;
	flex-direction: column;
	justify-content: end;
}

.section-1-content .overflow-content {
	background-color: var(--white);
	transform: translateX(15%);
	padding: 10rem 3rem 10rem 0;
}

.section-1-content h6 {
	color: var(--black);
	font-family: Font2;
	font-size: 15px;
	font-weight: 700;
	line-height: 27px;
}

.section-1-content h6  span{ 
	color: var(--red);
}

.section-1-content .image-container {
	width: 60%;
}

.section-1-content .image-container img{
	width: 70%;
	background-attachment: fixed;
}

.section-2-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100vh;
	background-color: var(--red);
}

.section-2-content .image-container {
	width: 50%;
	margin: 0 10px;
}

.section-2-content .image-container img{
	width: 100%;
}

.section-2-content .text-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 35%;
	margin-right: 4rem;
}

.section-2-content h6 {
	color: var(--white);
	font-family: Font2;
	font-size: 15px;
	font-weight: 700;
	line-height: 27px;
}

.section-3-content {
	height: 72vh;
}

.section-3-content .text-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60%;
	margin: 4rem auto;
}

.section-3-content h6 {
	color: var(--black);
	font-family: Font2;
	font-size: 15px;
	font-weight: 700;
	line-height: 27px;
}

.section-3-content .card-container {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin: 0 3rem;
	gap: 2rem;
}

.section-3-content .card-container .text-card{ 
	background-color: var(--red);
	padding: 3rem;
	color: var(--white);
	font-family: Font2;
	font-size: 15px;
	font-weight: 600;
	line-height: 30px;
	z-index: 10;
	width: 33%;
}

.section-3-content .card-container .card-2{ 
	margin-bottom: -6.5rem;
}
.section-3-content .card-container .card-3{ 
	margin-bottom: -13rem;
}

.footer-content {
	height: max-content;
	margin-top: 20rem;
}

.footer-content .text-container {
	width: 55%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-bottom: 4rem;
}

.footer-content .hr {
	border-bottom: 1px solid rgba(51, 51, 51, 1);
	width: 80%;
	margin: 0 auto;
}

.footer-content h2 {
	font-family: Font2;
	font-size: 35px;
	font-weight: 700;
	line-height: 40px;
	text-transform: uppercase;
	color: var(--white)
}

.footer-content h2 span {
	color: var(--red);
}

.footer-content h6 span {
	color: var(--red);
}


.footer-content h6 {
	font-family: Font2;
	font-size: 15px;
	font-weight: 700;
	line-height: 27px;

}

.footer-content .footer-image {
	width: 50%;
	margin: 3rem auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.footer-content h4 {
	text-align: center;
	color: var(--white);
	font-family: Font2;
	font-size: 15px;
	font-weight: 700;
	line-height: 27px;
}

.slider-container {
  position: relative;
  display: flex; /* Center text horizontally and vertically */
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  flex-direction: column; /* Added this line */
}

.mint-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  transition: .2s;
  margin-top: 10px;
}

.mint-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #A32036;
  cursor: pointer;
}

.mint-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #A32036;
  cursor: pointer;
}

.slider-value {
  color: #A32036;
  font-family: 'Font2', sans-serif;
  font-size: 30px;
  text-align: center;
}

.form-container {
  display: flex;
  font-family: 'Font2', sans-serif;
  flex-direction: column;
  align-items: center; /* align items to center */
  padding: 20px;
  background-color: #A32036;
  border-radius: 10px;
  color: white;
  width: 80%;
  margin: 0 0 10px auto; /* set the form container to the right */
}

.claim-form select {
  display: flex;
  flex-direction: column;
  width: 80%; /* adjust width as needed */
  padding: 10px;
  margin: 10px 10px 20px;
  border: 2px solid white;
  background-color: transparent;
  color: white;
}

.form-container label {
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
}

.form-container button[type="submit"] {
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: white;
  color: #A32036;
  cursor: pointer;
  border-radius: 5px;
}

.form-container button[type="submit"]:hover {
  background-color: #f8f8f8;
}

.dropdown-button {
	padding: 10px 15px;
	background-color: var(--gold);
	color: white;
	border: none;
	cursor: pointer;
	font-size: 16px;
	position: relative;
	font-family: 'Font2', sans-serif;
}

.dropdown-menu {
  font-family: 'Font2', sans-serif;
  position: absolute; 
  top: 100%; /* This positions the top of the menu at the bottom of the button */
  right: 70px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 90px;
  z-index: 1;
  margin: 0;
  padding: 0;
  display: flex;
  margin-top: 5px;
  text-align: left;
  list-style-type: none; 
  margin: 3px auto;
  overflow: hidden;
}

.dropdown-menu.open {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dropdown-menu li {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10px 20px;
	cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f5f5f5; 
}


/* Put your existing CSS here */

@media screen and (max-width: 1480px) {
  /* Adjust styles for screens that are 600px wide or less */

  .hero-content p {
	margin-top: 4rem;
	padding: 0;
  }

  .hero-content-head {
	padding: 0 3rem;
  }

  .hero-text-container {
	padding: 0 3rem;
  }

  .header-content {
	background-position: top;
	background-color: var(--black);
  }

  .footer-content {
	margin-top: 28rem;
  }

  .section-1-content .text-container {
	justify-content: center;
  }

}

@media screen and (max-width: 600px) {
  /* Adjust styles for screens that are 600px wide or less */

  .app-header {
    flex-direction: column;
    align-items: center;
  }

  .logo {
    height: 40px;
  }

  .header-button {
    font-size: 14px;
    padding: 8px 28px;
  }

  .content-button {
    font-size: 14px;
    padding: 8px 28px;
  }

  .title {
    font-size: 80px;
  }

  .subtitle {
    margin-top: -50px;
    margin-bottom: 40px;
	font-family: Montserrat;
	font-size: 40px;
	font-weight: 700;
	line-height: 40px;
	letter-spacing: 0em;
	text-align: left;

  }

  .main-content {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }


  .main-content2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; 
    width: 90%; 
    margin: 0 auto;
  }
  
  .my-section {
    width: calc(100% - 100px);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .row {
    display: flex;
    width: 100%;
    margin-bottom: 50px;
  }
  
  .row:last-child {
    margin-bottom: 0;
  }
  
  .col {
    flex: 1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image {
    max-width: 100%;
    height: auto;
  }
  
  .section-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; 
    margin-bottom: 50px;
  }
  
 
  .image-container {
    align-self: flex-start;  
  }
  .text-container {
    align-self: flex-start; 
  }
  

  .right-side-image {
    width: 100px;
  }

  .slider-value {
    font-size: 24px;
  }

  .form-container {
    width: 80%;
  }


  .hero-content-head {
	flex-direction: column;
	padding: 0;
	margin-top: 8rem;
	height: auto;
  }

  .hero-content .text-container {
	align-self: center;
	width: 100%;
	margin: auto;
  }

  .hero-content h2 {
	text-align: center;
	font-size: 40px;
	line-height: 30px;
	margin-bottom: 4rem;
  }
  
  .hero-content h3 {
	text-align: center;
	font-size: 30px;
	line-height: 30px;
  }

  .image-container2 {
	margin-right: 0;
  }

  .hero-text-container {
	flex-direction: column;
	margin-top: 3rem;
	width: 100%;
	padding: 0;
	margin-bottom: 0;
  }

  .hero-text-container a {
	align-items: center;
	display: flex;
	flex-direction: column-reverse;
	gap: .8rem;
	justify-content: center;
  }

  .hero-text-container p {
	font-size: 12px;
	line-height: 20px;
  }

  .hero-text-container p br:nth-child(1),  .hero-text-container p br:nth-child(2),  .hero-text-container p br:nth-child(5) {
	display: none;
  }

  .header-content {
	background-position: top;
	background-color: var(--black);
	box-sizing: border-box;
  }

  .header-content .text-container {
	width: 90%;
	margin: auto;
	padding-top: 6rem;
  }

  .main-app {
	margin: 20px;
  }

  .dropdown-menu {
	 right: 95px;
  }

  .section-1-content {
	overflow: hidden;
  }

  .section-1-content .text-container {
	width: 100%;
  }

  .section-1-content .text-container .overflow-content{
	width: 90%;
	height: 100%;
	padding: 0;
	margin: auto;
	transform: none;
  }

  .section-1-content .image-container {
	width: 90%;
	margin: auto;
	margin-bottom: -.5rem;
  }

  .section-2-content {
	height: auto;
  }

  .section-2-content .image-container {
	width: 90%;
	margin: 2rem auto;
}

.section-2-content .text-container {
	width: 90%;
	margin: 0 auto;
	margin-top: -4rem;
  }

  .section-2-content .text-container br {
	display: none;
  }

  .section-2-content .text-container br:nth-last-child(6),
  .section-2-content .text-container br:nth-last-child(7),
  .section-2-content .text-container br:nth-last-child(8),
  .section-2-content .text-container br:nth-last-child(9) {
	display: block;
  }

  .section-3-content {
	height: auto;
  }

  .section-3-content .text-container {
	width: 90%;
	margin: auto;
  }

  .section-3-content .text-container br {
	display: none;
  }

  .section-3-content .card-container {
	flex-direction: column;
	padding: 0;
	margin: auto ;
  }

  .card-container .text-card {
	box-sizing: border-box;
	width: 100%!important;
	margin: 0!important;
	padding: 1.2rem!important;
  }

  .text-card br {
	display: none;
  }

  .footer-content {
	margin-top: 2rem;
  }

  .footer-content .text-container {
	width: 100%;
  }

  .footer-content h2 {
	font-size: 20px;
	line-height: 30px;
  }

  .footer-content h2 br {
	display: none;
  }

  .footer-content h6 {
	font-size: 12px;
	line-height: 25px;
  }

   .claim-app {
	background: var(--white);
  }

  .claim-app .text-container {
	display: flex;
	flex-direction: column;
	align-items: center;
  }

  .claim-app .hero-content h2 {
	font-size: 35px;
	line-height: 30px;
  }

  .claim-app .hero-content h3 {
	font-size: 25px;
	line-height: 30px;
  }

  .claim-app .hero-content p {
	font-size: 12px;
	line-height: 20px;
	text-align: center;
	width: 80%;
	margin: 2rem auto;
  }

  .claim-app input {
	width: 100%;
  }

  .claim-app .claim-form > div {
	flex-direction: column;
  }


  .claim-section .footer-content {
	margin-top: 25rem;
  }

  .claim-check {
	flex-direction: row!important;
	font-size: 12px;
	line-height: 20px;
	width: 100%;
}

.claim-check input {
	width: fit-content;
}

.claim-form .claim-submit, .claim-form .claim-select {
	width: fit-content;
	margin: 1rem auto;
}

.claim-form {
	width: 80%;
}

}



